




















import { CreditType, ICreditItem } from '@/api/type/base';
import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
  name: ''
})
export default class extends Vue {
  @Prop()
  item!: ICreditItem;

  calcAddOrSub(item: ICreditItem) {
    const { status } = item;
    const addStatus = [CreditType.GET, CreditType.UNFREEZE, CreditType.RETURN, CreditType.REFUND, CreditType.CONVERSION];
    if (addStatus.includes(status)) {
      return true;
    } else {
      return false;
    }
  }
}
